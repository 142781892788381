//login
export const SIGN_IN = "SIGN_IN";

// USER
export const GET_ALL_USER = "GET_ALL_USER";
export const SET_USER = "SET_USER";
export const UPDATE_USER = "UPDATE_USER";
export const DELETE_USER = "DELETE_USER";
export const DELETE_MULTIPLE_USER = "DELETE_MULTIPLE_USER";

// VILLA
export const GET_ALL_VILLA = "GET_ALL_VILLA";
export const SET_VILLA = "SET_VILLA";
export const UPDATE_VILLA = "UPDATE_VILLA";
export const DELETE_VILLA = "DELETE_VILLA";
export const DELETE_MULTIPLE_VILLA = "DELETE_MULTIPLE_VILLA";

// APARTMENT
export const GET_ALL_APARTMENT = "GET_ALL_APARTMENT";
export const SET_APARTMENT = "SET_APARTMENT";
export const UPDATE_APARTMENT = "UPDATE_APARTMENT";
export const DELETE_APARTMENT = "DELETE_APARTMENT";
export const DELETE_MULTIPLE_APARTMENT = "DELETE_MULTIPLE_APARTMENT";

// HOTEL
export const GET_ALL_HOTEL = "GET_ALL_HOTEL";
export const SET_HOTEL = "SET_HOTEL";
export const UPDATE_HOTEL = "UPDATE_HOTEL";
export const DELETE_HOTEL = "DELETE_HOTEL";
export const DELETE_MULTIPLE_HOTEL = "DELETE_MULTIPLE_HOTEL";

// RENTAL_YACHT
export const GET_ALL_RENTAL_YACHT = "GET_ALL_RENTAL_YACHT";
export const SET_RENTAL_YACHT = "SET_RENTAL_YACHT";
export const UPDATE_RENTAL_YACHT = "UPDATE_RENTAL_YACHT";
export const DELETE_RENTAL_YACHT = "DELETE_RENTAL_YACHT";
export const DELETE_MULTIPLE_RENTAL_YACHT = "DELETE_MULTIPLE_RENTAL_YACHT";

// TRANSFER
export const GET_ALL_TRANSFER = "GET_ALL_TRANSFER";
export const SET_TRANSFER = "SET_TRANSFER";
export const UPDATE_TRANSFER = "UPDATE_TRANSFER";
export const DELETE_TRANSFER = "DELETE_TRANSFER";
export const DELETE_MULTIPLE_TRANSFER = "DELETE_MULTIPLE_TRANSFER";

//INQUIRY
//PROPERTY
export const GET_ALL_INQUIRY_PROPERTY = "GET_ALL_INQUIRY_PROPERTY";
export const DELETE_INQUIRY_PROPERTY = "DELETE_INQUIRY_PROPERTY";
export const DELETE_MULTIPLE_INQUIRY_PROPERTY =
  "DELETE_MULTIPLE_INQUIRY_PROPERTY";

//HELP
export const GET_ALL_INQUIRY_HELP = "GET_ALL_INQUIRY_HELP";
export const DELETE_INQUIRY_HELP = "DELETE_INQUIRY_HELP";
export const DELETE_MULTIPLE_INQUIRY_HELP = "DELETE_MULTIPLE_INQUIRY_HELP";

//PRIVATE_JET
export const GET_ALL_INQUIRY_PRIVATE_JET = "GET_ALL_INQUIRY_PRIVATE_JET";
export const DELETE_INQUIRY_PRIVATE_JET = "DELETE_INQUIRY_PRIVATE_JET";
export const DELETE_MULTIPLE_INQUIRY_PRIVATE_JET =
  "DELETE_MULTIPLE_INQUIRY_PRIVATE_JET";

//YACHT
export const GET_ALL_INQUIRY_YACHT = "GET_ALL_INQUIRY_YACHT";
export const DELETE_INQUIRY_YACHT = "DELETE_INQUIRY_YACHT";
export const DELETE_MULTIPLE_INQUIRY_YACHT = "DELETE_MULTIPLE_INQUIRY_YACHT";

//ELITE_CAR
export const GET_ALL_INQUIRY_ELITE_CAR = "GET_ALL_INQUIRY_ELITE_CAR";
export const DELETE_INQUIRY_ELITE_CAR = "DELETE_INQUIRY_ELITE_CAR";
export const DELETE_MULTIPLE_INQUIRY_ELITE_CAR =
  "DELETE_MULTIPLE_INQUIRY_ELITE_CAR";

//TRANSFER
export const GET_ALL_INQUIRY_TRANSFER = "GET_ALL_INQUIRY_TRANSFER";
export const DELETE_INQUIRY_TRANSFER = "DELETE_INQUIRY_TRANSFER";
export const DELETE_MULTIPLE_INQUIRY_TRANSFER =
  "DELETE_MULTIPLE_INQUIRY_TRANSFER";

//TLT_TRANSFER
export const GET_ALL_INQUIRY_TLT_TRANSFER = "GET_ALL_INQUIRY_TLT_TRANSFER";
export const DELETE_INQUIRY_TLT_TRANSFER = "DELETE_INQUIRY_TLT_TRANSFER";
export const DELETE_MULTIPLE_INQUIRY_TLT_TRANSFER =
  "DELETE_MULTIPLE_INQUIRY_TLT_TRANSFER";

// LIFESTYLE
export const GET_ALL_INQUIRY_LIFESTYLE = "GET_ALL_INQUIRY_LIFESTYLE";
export const DELETE_INQUIRY_LIFESTYLE = "DELETE_INQUIRY_LIFESTYLE";
export const DELETE_MULTIPLE_INQUIRY_LIFESTYLE =
  "DELETE_MULTIPLE_INQUIRY_LIFESTYLE";

// MASSAGE
export const GET_ALL_INQUIRY_MASSAGE = "GET_ALL_INQUIRY_MASSAGE";
export const DELETE_INQUIRY_MASSAGE = "DELETE_INQUIRY_MASSAGE";
export const DELETE_MULTIPLE_INQUIRY_MASSAGE =
  "DELETE_MULTIPLE_INQUIRY_MASSAGE";

// PERSONAL TRAINING
export const GET_ALL_INQUIRY_PERSONAL_TRAINING =
  "GET_ALL_INQUIRY_PERSONAL_TRAINING";
export const DELETE_INQUIRY_PERSONAL_TRAINING =
  "DELETE_INQUIRY_PERSONAL_TRAINING";
export const DELETE_MULTIPLE_INQUIRY_PERSONAL_TRAINING =
  "DELETE_MULTIPLE_INQUIRY_PERSONAL_TRAINING";

// PRIVATE RUNNER
export const GET_ALL_INQUIRY_PRIVATE_RUNNER = "GET_ALL_INQUIRY_PRIVATE_RUNNER";
export const DELETE_INQUIRY_PRIVATE_RUNNER = "DELETE_INQUIRY_PRIVATE_RUNNER";
export const DELETE_MULTIPLE_INQUIRY_PRIVATE_RUNNER =
  "DELETE_MULTIPLE_INQUIRY_PRIVATE_RUNNER";

// PAYMENT
export const GET_ONE_PAYMENT = "GET_ONE_PAYMENT";
