import { FaEyeSlash, FaRegEye, FaRegUser } from "react-icons/fa";
import { MdApartment, MdOutlineDashboard } from "react-icons/md";
import { FaWpforms } from "react-icons/fa6";
import { GiVillage } from "react-icons/gi";

const userDataSignIn = ({
  error,
  email,
  showPass,
  password,
  onShowPassword,
}) => {
  return [
    {
      id: 0,
      error: error.email,
      type: "text",
      field: "email",
      placeholder: "User Email",
      value: email,
    },
    {
      id: 1,
      error: error.password,
      type: showPass ? "text" : "password",
      field: "password",
      placeholder: "Password",
      value: password,
      passwordimg: showPass ? <FaRegEye /> : <FaEyeSlash />,
      onShowPassword: onShowPassword,
    },
  ];
};

const headerData = [
  {
    pathname: "/",
    pageName: "Dashboard",
    img: <MdOutlineDashboard />,
    roles: ["admin"],
  },
  {
    pathname: "/user-list",
    pageName: "User",
    img: <FaRegUser />,
    roles: ["admin", "subadmin"],
  },
  {
    pathname: "/inquiry/villa",
    pageName: "Inquiry",
    img: <FaWpforms />,
    roles: ["admin", "subadmin"],
  },
  {
    pathname: "/villa",
    pageName: "Villa",
    img: <GiVillage />,
    roles: ["admin", "subadmin"],
  },
  {
    pathname: "/apartment",
    pageName: "Apartment",
    img: <MdApartment />,
    roles: ["admin", "subadmin"],
  },
  {
    pathname: "/hotel",
    pageName: "Hotel",
    img: <MdApartment />,
    roles: ["admin", "subadmin"],
  },
  {
    pathname: "/yacht",
    pageName: "Yacht",
    img: <MdApartment />,
    roles: ["admin", "subadmin"],
  },
  {
    pathname: "/transfer",
    pageName: "Transfer",
    img: <MdApartment />,
    roles: ["admin", "subadmin"],
  },
];

const addUser = ({ error, formData }) => {
  return [
    {
      id: 1,
      error: error?.email,
      field: "email",
      type: "text",
      placeholder: "email",
      value: formData?.email,
      readOnly: true,
    },
    {
      id: 2,
      error: error?.userName,
      field: "userName",
      type: "text",
      placeholder: "userName",
      value: formData?.userName,
      readOnly: true,
    },
    {
      id: 3,
      error: error?.phoneNumber,
      field: "phoneNumber",
      type: "text",
      placeholder: "phoneNumber",
      value: formData?.phoneNumber,
      readOnly: true,
    },
    {
      id: 4,
      error: error?.dob,
      field: "dob",
      type: "date",
      placeholder: "dob",
      value: formData?.dob,
      readOnly: true,
    },
    {
      id: 5,
      error: error?.socialMedia,
      field: "socialMedia",
      type: "text",
      placeholder: "socialMedia",
      value: formData?.socialMedia,
      readOnly: true,
    },
    {
      id: 6,
      error: error?.findUs,
      field: "findUs",
      type: "text",
      placeholder: "findUs",
      value: formData?.findUs,
      readOnly: true,
    },
    {
      id: 8,
      error: error?.hobbies,
      field: "hobbies",
      type: "text",
      placeholder: "hobbies",
      value: formData?.hobbies,
      readOnly: true,
    },
    {
      id: 7,
      error: error?.status,
      field: "status",
      type: "select",
      placeholder: "status",
      value: formData?.status,
      options: [
        { value: "activate", label: "Activate" },
        { value: "deactivate", label: "Deactivate" },
      ],
    },
  ];
};

const addTransferData = ({ error, formData }) => {
  return [
    {
      id: 1,
      error: error?.images,
      field: "images",
      type: "file",
      placeholder: "images",
      value: formData?.images,
    },
    {
      id: 2,
      error: error?.name,
      field: "name",
      type: "text",
      placeholder: "name",
      value: formData?.name,
    },
    {
      id: 3,
      error: error?.model,
      field: "model",
      type: "text",
      placeholder: "model",
      value: formData?.model,
    },
    {
      id: 4,
      error: error?.status,
      field: "status",
      type: "select",
      placeholder: "status",
      value: formData?.status,
      options: [
        { value: "activate", label: "Activate" },
        { value: "deactivate", label: "Deactivate" },
      ],
    },
  ];
};

const addVilla = ({ error, formData }) => {
  return [
    {
      id: 1,
      error: error?.images,
      field: "images",
      type: "file",
      placeholder: "images",
      value: formData?.images,
    },
    {
      id: 2,
      error: error?.name,
      field: "name",
      type: "text",
      placeholder: "name",
      value: formData?.name,
    },
    {
      id: 5,
      error: error?.guests,
      field: "guests",
      type: "number",
      placeholder: "guests",
      value: formData?.guests,
    },
    {
      id: 6,
      error: error?.bedrooms,
      field: "bedrooms",
      type: "number",
      placeholder: "bedrooms",
      value: formData?.bedrooms,
    },
    {
      id: 7,
      error: error?.beds,
      field: "beds",
      type: "number",
      placeholder: "beds",
      value: formData?.beds,
    },
    {
      id: 8,
      error: error?.bathrooms,
      field: "bathrooms",
      type: "number",
      placeholder: "bathrooms",
      value: formData?.bathrooms,
    },
    {
      id: 9,
      error: error?.location,
      field: "location",
      type: "text",
      placeholder: "location",
      value: formData?.location,
    },
    {
      id: 12,
      error: error?.locationUrl,
      field: "locationUrl",
      type: "url",
      placeholder: "locationUrl",
      value: formData?.locationUrl,
    },
    {
      id: 11,
      error: error?.status,
      field: "status",
      type: "select",
      placeholder: "status",
      value: formData?.status,
      options: [
        { value: "activate", label: "Activate" },
        { value: "deactivate", label: "Deactivate" },
      ],
    },
    {
      id: 10,
      error: error?.description,
      field: "description",
      type: "textarea",
      placeholder: "description",
      value: formData?.description,
    },
  ];
};

const addHotel = ({ error, formData }) => {
  return [
    {
      id: 1,
      error: error?.images,
      field: "images",
      type: "file",
      placeholder: "images",
      value: formData?.images,
    },
    {
      id: 2,
      error: error?.name,
      field: "name",
      type: "text",
      placeholder: "name",
      value: formData?.name,
    },
    {
      id: 5,
      error: error?.location,
      field: "location",
      type: "text",
      placeholder: "location",
      value: formData?.location,
    },
    {
      id: 6,
      error: error?.description,
      field: "description",
      type: "textarea",
      placeholder: "description",
      value: formData?.description,
    },
    {
      id: 8,
      error: error?.status,
      field: "status",
      type: "select",
      placeholder: "status",
      value: formData?.status,
      options: [
        { value: "activate", label: "Activate" },
        { value: "deactivate", label: "Deactivate" },
      ],
    },
  ];
};

const addYacht = ({ error, formData }) => {
  return [
    {
      id: 1,
      error: error?.images,
      field: "images",
      type: "file",
      placeholder: "images",
      value: formData?.images,
    },
    {
      id: 2,
      error: error?.name,
      field: "name",
      type: "text",
      placeholder: "name",
      value: formData?.name,
    },
    {
      id: 3,
      error: error?.pdfFile,
      field: "pdfFile",
      accept: ".pdf",
      type: "file",
      placeholder: "pdfFile",
      value: formData?.pdfFile,
    },
    {
      id: 5,
      error: error?.description,
      field: "description",
      type: "textarea",
      placeholder: "description",
      value: formData?.description,
    },
    {
      id: 4,
      error: error?.status,
      field: "status",
      type: "select",
      placeholder: "status",
      value: formData?.status,
      options: [
        { value: "activate", label: "Activate" },
        { value: "deactivate", label: "Deactivate" },
      ],
    },
  ];
};
const addPaymentLink = ({ error, formData, id }) => {
  return [
    {
      id: 1,
      error: error?.title,
      field: "title",
      type: "text",
      placeholder: "title",
      value: formData?.title,
    },
    {
      id: 2,
      error: error?.message,
      field: "message",
      type: "textarea",
      placeholder: "message",
      value: formData?.message,
    },
    {
      id: 3,
      error: error?.finalPrice,
      field: "finalPrice",
      type: "number",
      placeholder: "€finalPrice",
      value: formData?.finalPrice,
    },
    // {
    //   id: 4,
    //   error: error?.extraOffer,
    //   field: "extraOffer",
    //   type: "number",
    //   placeholder: "extraOffer",
    //   value: formData?.extraOffer,
    //   isOptional: true,
    // },
    {
      id: 5,
      error: error?.paymentId,
      field: "paymentId",
      type: "text",
      placeholder: "paymentId",
      value: formData?.paymentId,
      readOnly: true,
      isOptional: true,
    },
    {
      id: 6,
      error: error?.paymentStatus,
      field: "paymentStatus",
      type: "text",
      placeholder: "paymentStatus",
      value: formData?.paymentStatus,
      readOnly: true,
      isOptional: true,
    },
    {
      id: 13,
      error: error?.orderStatus,
      field: "orderStatus",
      type: "text",
      placeholder: "orderStatus",
      value: formData?.orderStatus,
      readOnly: true,
      isOptional: true,
    },
    {
      id: 7,
      error: error?.refundStatus,
      field: "refundStatus",
      type: "text",
      placeholder: "refundStatus",
      value: formData?.refundStatus,
      readOnly: true,
      isOptional: true,
    },
    {
      id: 8,
      error: error?.paymentLinkId,
      field: "paymentLinkId",
      type: "text",
      placeholder: "paymentLinkId",
      value: formData?.paymentLinkId,
      readOnly: true,
      isOptional: true,
    },

    {
      id: 9,
      error: error?.paymentLink,
      field: "paymentLink",
      type: "text",
      placeholder: "paymentLink",
      value: formData?.paymentLink,
      readOnly: true,
      isOptional: true,
    },
    {
      id: 10,
      error: error?.refundId,
      field: "refundId",
      type: "text",
      placeholder: "refundId",
      value: formData?.refundId,
      readOnly: true,
      isOptional: true,
    },
    {
      id: 11,
      error: error?.refundAmount,
      field: "refundAmount",
      type: "text",
      placeholder: "refundAmount",
      value: formData?.refundAmount,
      readOnly: true,
      isOptional: true,
    },
    {
      id: 12,
      error: error?.refundReason,
      field: "refundReason",
      type: "textarea",
      placeholder: "refundReason",
      value: formData?.refundReason,
      readOnly: true,
      isOptional: true,
    },
  ];
};

const refundPayment = ({ error, formData }) => {
  return [
    {
      id: 1,
      error: error?.paymentId,
      field: "paymentId",
      type: "text",
      placeholder: "paymentId",
      value: formData?.paymentId,
      readOnly: true,
      isOptional: true,
    },
    {
      id: 2,
      error: error?.totalAmount,
      field: "totalAmount",
      type: "number",
      placeholder: "totalAmount",
      value: formData?.totalAmount,
      readOnly: true,
      isOptional: true,
    },
    {
      id: 3,
      error: error?.refundAmount,
      field: "refundAmount",
      type: "number",
      placeholder: "refundAmount",
      value: formData?.refundAmount,
    },
    {
      id: 4,
      error: error?.reason,
      field: "reason",
      type: "textarea",
      placeholder: "reason",
      value: formData?.reason,
    },
  ];
};

const inquiryHelp = ({ formData }) => {
  return [
    {
      id: 1,
      field: "firstName",
      type: "text",
      placeholder: "firstName",
      value: formData?.firstName,
      readOnly: true,
    },
    {
      id: 2,
      field: "lastName",
      type: "text",
      placeholder: "lastName",
      value: formData?.lastName,
      readOnly: true,
    },
    {
      id: 3,
      field: "phone",
      type: "text",
      placeholder: "phone",
      value: formData?.phone,
      readOnly: true,
    },
    {
      id: 4,
      field: "email",
      type: "text",
      placeholder: "email",
      value: formData?.email,
      readOnly: true,
    },
    {
      id: 5,
      field: "message",
      type: "textarea",
      placeholder: "message",
      value: formData?.message,
      readOnly: true,
    },
    {
      id: 6,
      field: "status",
      type: "select",
      placeholder: "status",
      value: formData.status,
      options: [
        { value: "pending", label: "Pending" },
        { value: "inprocess", label: "InProcess" },
        { value: "complete", label: "Complete" },
      ],
      readOnly: false,
    },
  ];
};

const commonInquiry = ({ formData }) => {
  return [
    {
      id: 1,
      field: "fullName",
      type: "text",
      placeholder: "fullName",
      value: formData?.fullName,
      readOnly: true,
    },
    {
      id: 2,
      field: "phone",
      type: "text",
      placeholder: "phone",
      value: formData?.phone,
      readOnly: true,
    },
    {
      id: 4,
      field: "email",
      type: "text",
      placeholder: "email",
      value: formData?.email,
      readOnly: true,
    },
  ];
};

const inquiryPrivateJet = ({ formData }) => {
  return [
    ...commonInquiry({ formData }),
    {
      id: 6,
      field: "from",
      type: "text",
      placeholder: "from",
      value: formData?.from,
      readOnly: true,
    },
    {
      id: 7,
      field: "to",
      type: "text",
      placeholder: "to",
      value: formData?.to,
      readOnly: true,
    },
    {
      id: 8,
      field: "flyDate",
      type: "text",
      placeholder: "flyDate",
      value: formData?.flyDate,
      readOnly: true,
    },
    {
      id: 9,
      field: "passengers",
      type: "text",
      placeholder: "passengers",
      value: formData?.passengers,
      readOnly: true,
    },
    {
      id: 10,
      field: "message",
      type: "textarea",
      placeholder: "message",
      value: formData?.message,
      readOnly: true,
    },
  ];
};

const inquiryPrivateRunner = ({ formData }) => {
  return [
    ...commonInquiry({ formData }),
    {
      id: 6,
      field: "pickupTime",
      type: "datetime-local",
      placeholder: "pickupTime",
      value: formData?.pickupTime,
      readOnly: true,
    },
    {
      id: 10,
      field: "message",
      type: "textarea",
      placeholder: "message",
      value: formData?.message,
      readOnly: true,
    },
  ];
};

const inquiryPersonalTraining = ({ formData }) => {
  return [
    ...commonInquiry({ formData }),
    {
      id: 6,
      field: "bookingDate",
      type: "datetime-local",
      placeholder: "bookingDate",
      value: formData?.bookingDate,
      readOnly: true,
    },
    {
      id: 7,
      field: "numberOfGuests",
      type: "text",
      placeholder: "numberOfGuests",
      value: formData?.numberOfGuests,
      readOnly: true,
    },
    {
      id: 8,
      field: "packageType",
      type: "text",
      placeholder: "packageType",
      value: formData?.packageType,
      readOnly: true,
    },
    {
      id: 10,
      field: "message",
      type: "textarea",
      placeholder: "message",
      value: formData?.message,
      readOnly: true,
    },
  ];
};

const lifestylePrivateJet = ({ formData }) => {
  return [
    ...commonInquiry({ formData }),
    {
      id: 6,
      field: "bookingDate",
      type: "datetime-local",
      placeholder: "bookingDate",
      value: formData?.bookingDate,
      readOnly: true,
    },
    {
      id: 7,
      field: "categoryName",
      type: "text",
      placeholder: "categoryName",
      value: formData?.categoryName,
      readOnly: true,
    },
    {
      id: 8,
      field: "mainCategoryName",
      type: "text",
      placeholder: "mainCategoryName",
      value: formData?.mainCategoryName,
      readOnly: true,
    },
    {
      id: 9,
      field: "numberOfGuests",
      type: "text",
      placeholder: "numberOfGuests",
      value: formData?.numberOfGuests,
      readOnly: true,
    },
    {
      id: 10,
      field: "title",
      type: "text",
      placeholder: "title",
      value: formData?.title,
      readOnly: true,
    },
    {
      id: 11,
      field: "message",
      type: "textarea",
      placeholder: "message",
      value: formData?.message,
      readOnly: true,
    },
  ];
};

const massagePrivateJet = ({ formData }) => {
  return [
    ...commonInquiry({ formData }),
    {
      id: 6,
      field: "bookingDate",
      type: "datetime-local",
      placeholder: "bookingDate",
      value: formData?.bookingDate,
      readOnly: true,
    },
    {
      id: 9,
      field: "numberOfGuests",
      type: "text",
      placeholder: "numberOfGuests",
      value: formData?.numberOfGuests,
      readOnly: true,
    },
    {
      id: 11,
      field: "message",
      type: "textarea",
      placeholder: "message",
      value: formData?.message,
      readOnly: true,
    },
  ];
};

const inquiryYacht = ({ formData }) => {
  return [
    ...commonInquiry({ formData }),
    {
      id: 6,
      field: "season",
      type: "text",
      placeholder: "season",
      value: formData?.season,
      readOnly: true,
    },
    {
      id: 7,
      field: "title",
      type: "text",
      placeholder: "title",
      value: formData?.title,
      readOnly: true,
    },
    {
      id: 11,
      field: "message",
      type: "textarea",
      placeholder: "message",
      value: formData?.message,
      readOnly: true,
    },
  ];
};

const transferYacht = ({ formData }) => {
  return [
    ...commonInquiry({ formData }),
    {
      id: 6,
      field: "flightNumber",
      type: "text",
      placeholder: "flightNumber",
      value: formData?.flightNumber,
      readOnly: true,
    },
    {
      id: 7,
      field: "passengers",
      type: "text",
      placeholder: "passengers",
      value: formData?.passengers,
      readOnly: true,
    },
    {
      id: 8,
      field: "pickupAddress",
      type: "text",
      placeholder: "pickupAddress",
      value: formData?.pickupAddress,
      readOnly: true,
    },
    {
      id: 9,
      field: "pickupDate",
      type: "datetime-local",
      placeholder: "pickupDate",
      value: formData?.pickupDate,
      readOnly: true,
    },
    {
      id: 10,
      field: "selectOption",
      type: "text",
      placeholder: "selectOption",
      value: formData?.selectOption,
      readOnly: true,
    },
    {
      id: 11,
      field: "luggages",
      type: "text",
      placeholder: "luggages",
      value: formData?.luggages,
      readOnly: true,
    },
    {
      id: 12,
      field: "dropOffAddress",
      type: "text",
      placeholder: "dropOffAddress",
      value: formData?.dropOffAddress,
      readOnly: true,
    },
    {
      id: 14,
      field: "title",
      type: "text",
      placeholder: "title",
      value: formData?.title,
      readOnly: true,
    },
    {
      id: 13,
      field: "message",
      type: "textarea",
      placeholder: "message",
      value: formData?.message,
      readOnly: true,
    },
  ];
};

const property = ({ formData }) => {
  return [
    ...commonInquiry({ formData }),
    {
      id: 6,
      field: "checkIn",
      type: "text",
      placeholder: "checkIn",
      value: formData?.checkIn,
      readOnly: true,
    },
    {
      id: 7,
      field: "checkOut",
      type: "text",
      placeholder: "checkOut",
      value: formData?.checkOut,
      readOnly: true,
    },
    {
      id: 14,
      field: "title",
      type: "text",
      placeholder: "title",
      value: formData?.title,
      readOnly: true,
    },
    {
      id: 13,
      field: "message",
      type: "textarea",
      placeholder: "message",
      value: formData?.message,
      readOnly: true,
    },
  ];
};

const inquiryEliteCar = ({ formData }) => {
  return [
    ...commonInquiry({ formData }),
    {
      id: 6,
      field: "dropOffAddress",
      type: "text",
      placeholder: "dropOffAddress",
      value: formData?.dropOffAddress,
      readOnly: true,
    },
    {
      id: 7,
      field: "dropOffDate",
      type: "datetime-local",
      placeholder: "dropOffDate",
      value: formData?.dropOffDate,
      readOnly: true,
    },
    {
      id: 8,
      field: "pickupAddress",
      type: "text",
      placeholder: "pickupAddress",
      value: formData?.pickupAddress,
      readOnly: true,
    },
    {
      id: 9,
      field: "pickupDate",
      type: "datetime-local",
      placeholder: "pickupDate",
      value: formData?.pickupDate,
      readOnly: true,
    },
    {
      id: 10,
      field: "price",
      type: "text",
      placeholder: "price",
      value: formData?.price,
      readOnly: true,
    },
    {
      id: 10,
      field: "title",
      type: "text",
      placeholder: "title",
      value: formData?.title,
      readOnly: true,
    },
    {
      id: 13,
      field: "message",
      type: "textarea",
      placeholder: "message",
      value: formData?.message,
      readOnly: true,
    },
  ];
};

export {
  userDataSignIn,
  inquiryHelp,
  inquiryPrivateJet,
  inquiryYacht,
  inquiryEliteCar,
  transferYacht,
  lifestylePrivateJet,
  massagePrivateJet,
  inquiryPersonalTraining,
  inquiryPrivateRunner,
  headerData,
  addUser,
  addPaymentLink,
  refundPayment,
  addVilla,
  addHotel,
  property,
  addYacht,
  addTransferData,
};
