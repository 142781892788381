import { lazy } from "react";

const RoutesPaths = [
  {
    path: "/login",
    component: lazy(() => import("../../Components/SignIn/Login")),
    meta: {
      authRoute: false,
      roles: ["all"],
    },
  },
  {
    path: "/",
    component: lazy(() => import("../../Components/Dashboard/Dashboard")),
    meta: {
      authRoute: true,
      roles: ["subadmin", "admin"],
    },
  },
  {
    path: "/user-list",
    component: lazy(() => import("../../Components/User/User")),
    meta: {
      authRoute: true,
      roles: ["subadmin", "admin"],
    },
  },
  {
    path: "/villa",
    component: lazy(() => import("../../Components/Villa/Villa")),
    meta: {
      authRoute: true,
      roles: ["subadmin", "admin"],
    },
  },
  {
    path: "/apartment",
    component: lazy(() => import("../../Components/Apartment/Apartment")),
    meta: {
      authRoute: true,
      roles: ["subadmin", "admin"],
    },
  },
  {
    path: "/yacht",
    component: lazy(() => import("../../Components/Yacht/Yacht")),
    meta: {
      authRoute: true,
      roles: ["subadmin", "admin"],
    },
  },
  {
    path: "/transfer",
    component: lazy(() => import("../../Components/Transfer/Transfer")),
    meta: {
      authRoute: true,
      roles: ["subadmin", "admin"],
    },
  },
  {
    path: "/hotel",
    component: lazy(() => import("../../Components/Hotel/Hotel")),
    meta: {
      authRoute: true,
      roles: ["subadmin", "admin"],
    },
  },
  {
    path: "/create-payment-link/:id",
    component: lazy(() => import("../../Components/Payment/CreatePaymentLink")),
    meta: {
      authRoute: true,
      roles: ["subadmin", "admin"],
    },
  },
  {
    path: "/myAccount",
    component: lazy(() => import("../../Components/Profile/Profile")),
    meta: {
      authRoute: true,
      roles: ["subadmin", "admin"],
    },
    children: [
      {
        path: "changePassword",
        component: lazy(() =>
          import("../../Components/Profile/ChangePassword/ChangePassword")
        ),
        meta: {
          authRoute: true,
          roles: ["subadmin", "admin"],
        },
      },
    ],
  },

  {
    path: "/inquiry",
    component: lazy(() => import("../../Components/Inquiry/Inquiry")),
    meta: {
      authRoute: true,
      roles: ["subadmin", "admin"],
    },
    children: [
      {
        path: "villa",
        component: lazy(() => import("../../Components/Inquiry/Villa/Villa")),
        meta: {
          authRoute: true,
          roles: ["subadmin", "admin"],
        },
      },
      {
        path: "apartment",
        component: lazy(() =>
          import("../../Components/Inquiry/Apartment/Apartment")
        ),
        meta: {
          authRoute: true,
          roles: ["subadmin", "admin"],
        },
      },
      {
        path: "hotel",
        component: lazy(() => import("../../Components/Inquiry/Hotel/Hotel")),
        meta: {
          authRoute: true,
          roles: ["subadmin", "admin"],
        },
      },
      {
        path: "help",
        component: lazy(() => import("../../Components/Inquiry/Help/Help")),
        meta: {
          authRoute: true,
          roles: ["subadmin", "admin"],
        },
      },
      {
        path: "privateJet",
        component: lazy(() =>
          import("../../Components/Inquiry/PrivateJet/PrivateJet")
        ),
        meta: {
          authRoute: true,
          roles: ["subadmin", "admin"],
        },
      },
      {
        path: "yacht",
        component: lazy(() => import("../../Components/Inquiry/Yacht/Yacht")),
        meta: {
          authRoute: true,
          roles: ["subadmin", "admin"],
        },
      },
      {
        path: "elite-car",
        component: lazy(() =>
          import("../../Components/Inquiry/EliteCar/EliteCar")
        ),
        meta: {
          authRoute: true,
          roles: ["subadmin", "admin"],
        },
      },
      {
        path: "transfer",
        component: lazy(() =>
          import("../../Components/Inquiry/Transfer/Transfer")
        ),
        meta: {
          authRoute: true,
          roles: ["subadmin", "admin"],
        },
      },
      {
        path: "tlt-transfer",
        component: lazy(() =>
          import("../../Components/Inquiry/TltTransfer/TltTransfer")
        ),
        meta: {
          authRoute: true,
          roles: ["subadmin", "admin"],
        },
      },
      {
        path: "beachClub",
        component: lazy(() =>
          import("../../Components/Inquiry/BeachClub/BeachClub")
        ),
        meta: {
          authRoute: true,
          roles: ["subadmin", "admin"],
        },
      },
      {
        path: "restaurant",
        component: lazy(() =>
          import("../../Components/Inquiry/Restaurant/Restaurant")
        ),
        meta: {
          authRoute: true,
          roles: ["subadmin", "admin"],
        },
      },
      {
        path: "nightClub",
        component: lazy(() =>
          import("../../Components/Inquiry/NightClub/NightClub")
        ),
        meta: {
          authRoute: true,
          roles: ["subadmin", "admin"],
        },
      },
      {
        path: "massage",
        component: lazy(() =>
          import("../../Components/Inquiry/Massage/Massage")
        ),
        meta: {
          authRoute: true,
          roles: ["subadmin", "admin"],
        },
      },
      {
        path: "personalTraining",
        component: lazy(() =>
          import("../../Components/Inquiry/PersonalTraining/PersonalTraining")
        ),
        meta: {
          authRoute: true,
          roles: ["subadmin", "admin"],
        },
      },
      {
        path: "privateRunner",
        component: lazy(() =>
          import("../../Components/Inquiry/PrivateRunner/PrivateRunner")
        ),
        meta: {
          authRoute: true,
          roles: ["subadmin", "admin"],
        },
      },
    ],
  },
];

export default RoutesPaths;
