import * as types from "./ActionType";

const initialState = {
  Token: null,
  User: [],
  Villa: [],
  Apartment: [],
  Hotel: [],
  Property: [],
  TransferData: [],
  Help: [],
  PrivateJet: [],
  RentalsYacht: [],
  Yacht: [],
  EliteCar: [],
  Transfer: [],
  TltTransfer: [],
  Lifestyle: [],
  Massage: [],
  PersonalTraining: [],
  OnePayment: null,
};

const userReducers = (state = initialState, action) => {
  const { type, payload } = action;

  const updateState = (key) => ({
    ...state,
    [key]: state[key].map((item) =>
      item._id === payload[`${key}Id`]
        ? { ...item, ...payload[`Updated${key}`] }
        : item
    ),
  });

  const deleteFromState = (key) => ({
    ...state,
    [key]: state[key].filter((item) => item._id !== payload[`${key}Id`]),
  });

  const deleteMultipleFromState = (key) => ({
    ...state,
    [key]: state[key].filter(
      (item) => !payload[`${key}Ids`].includes(item._id)
    ),
  });

  switch (type) {
    // Add cases for Login actions
    case types.SIGN_IN:
      return { ...state, Token: payload };

    // Add cases for User actions
    case types.GET_ALL_USER:
      return { ...state, User: payload };
    case types.SET_USER:
      return { ...state, User: [...state.User, payload] };
    case types.UPDATE_USER:
      return updateState("User");
    case types.DELETE_USER:
      return deleteFromState("User");
    case types.DELETE_MULTIPLE_USER:
      return deleteMultipleFromState("User");

    // Add cases for Villa actions
    case types.GET_ALL_VILLA:
      return { ...state, Villa: payload };
    case types.SET_VILLA:
      return { ...state, Villa: [...state.Villa, payload] };
    case types.UPDATE_VILLA:
      return updateState("Villa");
    case types.DELETE_VILLA:
      return deleteFromState("Villa");
    case types.DELETE_MULTIPLE_VILLA:
      return deleteMultipleFromState("Villa");

    // Add cases for Apartment actions
    case types.GET_ALL_APARTMENT:
      return { ...state, Apartment: payload };
    case types.SET_APARTMENT:
      return { ...state, Apartment: [...state.Apartment, payload] };
    case types.UPDATE_APARTMENT:
      return updateState("Apartment");
    case types.DELETE_APARTMENT:
      return deleteFromState("Apartment");
    case types.DELETE_MULTIPLE_APARTMENT:
      return deleteMultipleFromState("Apartment");

    // Add cases for RentalsYacht actions
    case types.GET_ALL_RENTAL_YACHT:
      return { ...state, RentalsYacht: payload };
    case types.SET_RENTAL_YACHT:
      return { ...state, RentalsYacht: [...state.RentalsYacht, payload] };
    case types.UPDATE_RENTAL_YACHT:
      return updateState("RentalsYacht");
    case types.DELETE_RENTAL_YACHT:
      return deleteFromState("RentalsYacht");
    case types.DELETE_MULTIPLE_RENTAL_YACHT:
      return deleteMultipleFromState("RentalsYacht");

    // Add cases for TransferData actions
    case types.GET_ALL_TRANSFER:
      return { ...state, TransferData: payload };
    case types.SET_TRANSFER:
      return { ...state, TransferData: [...state.TransferData, payload] };
    case types.UPDATE_TRANSFER:
      return updateState("TransferData");
    case types.DELETE_TRANSFER:
      return deleteFromState("TransferData");
    case types.DELETE_MULTIPLE_TRANSFER:
      return deleteMultipleFromState("TransferData");

    // Add cases for Hotel actions
    case types.GET_ALL_HOTEL:
      return { ...state, Hotel: payload };
    case types.SET_HOTEL:
      return { ...state, Hotel: [...state.Hotel, payload] };
    case types.UPDATE_HOTEL:
      return updateState("Hotel");
    case types.DELETE_HOTEL:
      return deleteFromState("Hotel");
    case types.DELETE_MULTIPLE_HOTEL:
      return deleteMultipleFromState("Hotel");

    // Add cases for Inquiry Property  PROPERTY actions
    case types.GET_ALL_INQUIRY_PROPERTY:
      return { ...state, Property: payload, loading: false };
    case types.DELETE_INQUIRY_PROPERTY:
      return deleteFromState("Property");
    case types.DELETE_MULTIPLE_INQUIRY_PROPERTY:
      return deleteMultipleFromState("Property");

    // Add cases for Inquiry Help actions
    case types.GET_ALL_INQUIRY_HELP:
      return { ...state, Help: payload, loading: false };
    case types.DELETE_INQUIRY_HELP:
      return deleteFromState("Help");
    case types.DELETE_MULTIPLE_INQUIRY_HELP:
      return deleteMultipleFromState("Help");

    // Add cases for Inquiry PrivateJet actions
    case types.GET_ALL_INQUIRY_PRIVATE_JET:
      return { ...state, PrivateJet: payload, loading: false };
    case types.DELETE_INQUIRY_PRIVATE_JET:
      return deleteFromState("PrivateJet");
    case types.DELETE_MULTIPLE_INQUIRY_PRIVATE_JET:
      return deleteMultipleFromState("PrivateJet");

    // Add cases for Inquiry Yacht actions
    case types.GET_ALL_INQUIRY_YACHT:
      return { ...state, Yacht: payload, loading: false };
    case types.DELETE_INQUIRY_YACHT:
      return deleteFromState("Yacht");
    case types.DELETE_MULTIPLE_INQUIRY_YACHT:
      return deleteMultipleFromState("Yacht");

    // Add cases for Inquiry EliteCar actions
    case types.GET_ALL_INQUIRY_ELITE_CAR:
      return { ...state, EliteCar: payload, loading: false };
    case types.DELETE_INQUIRY_ELITE_CAR:
      return deleteFromState("EliteCar");
    case types.DELETE_MULTIPLE_INQUIRY_ELITE_CAR:
      return deleteMultipleFromState("EliteCar");

    // Add cases for Inquiry Transfer actions
    case types.GET_ALL_INQUIRY_TRANSFER:
      return { ...state, Transfer: payload, loading: false };
    case types.DELETE_INQUIRY_TRANSFER:
      return deleteFromState("Transfer");
    case types.DELETE_MULTIPLE_INQUIRY_TRANSFER:
      return deleteMultipleFromState("Transfer");

    // Add cases for Inquiry TltTransfer actions
    case types.GET_ALL_INQUIRY_TLT_TRANSFER:
      return { ...state, TltTransfer: payload, loading: false };
    case types.DELETE_INQUIRY_TLT_TRANSFER:
      return deleteFromState("TltTransfer");
    case types.DELETE_MULTIPLE_INQUIRY_TLT_TRANSFER:
      return deleteMultipleFromState("TltTransfer");

    // Reducer cases for Lifestyle
    case types.GET_ALL_INQUIRY_LIFESTYLE:
      return { ...state, Lifestyle: payload, loading: false };
    case types.DELETE_INQUIRY_LIFESTYLE:
      return deleteFromState("Lifestyle");
    case types.DELETE_MULTIPLE_INQUIRY_LIFESTYLE:
      return deleteMultipleFromState("Lifestyle");

    // Reducer cases for MASSAGE
    case types.GET_ALL_INQUIRY_MASSAGE:
      return { ...state, Massage: payload, loading: false };
    case types.DELETE_INQUIRY_MASSAGE:
      return deleteFromState("Massage");
    case types.DELETE_MULTIPLE_INQUIRY_MASSAGE:
      return deleteMultipleFromState("Massage");

    // Reducer cases for PersonalTraining
    case types.GET_ALL_INQUIRY_PERSONAL_TRAINING:
      return { ...state, PersonalTraining: payload, loading: false };
    case types.DELETE_INQUIRY_PERSONAL_TRAINING:
      return deleteFromState("PersonalTraining");
    case types.DELETE_MULTIPLE_INQUIRY_PERSONAL_TRAINING:
      return deleteMultipleFromState("PersonalTraining");

    // Reducer cases for PrivateRunner
    case types.GET_ALL_INQUIRY_PRIVATE_RUNNER:
      return { ...state, PrivateRunner: payload, loading: false };
    case types.DELETE_INQUIRY_PRIVATE_RUNNER:
      return deleteFromState("PrivateRunner");
    case types.DELETE_MULTIPLE_INQUIRY_PRIVATE_RUNNER:
      return deleteMultipleFromState("PrivateRunner");

    // Reducer cases for OnePayment
    case types.GET_ONE_PAYMENT:
      return { ...state, OnePayment: payload };

    default:
      return state;
  }
};

export default userReducers;
