const styles = {
  flexCenter: "flex justify-center items-center",
  flexStart: "flex justify-center items-start",
  flexBetween: "flex justify-between items-center",
  model:
    "fixed h-modal h-full animated fadeInDown bg-[#000000bf]  overflow-auto z-50 inset-0",
  model1: "flex flex-col items-center justify-center px-6 py-8 mx-auto lg:py-0",
  model2: "w-full bg-[#fafafb] rounded-2xl  md:mt-0 xl:p-0",
  closeX: "absolute right-6 top-5 cursor-pointer",

  commonInput:
    "peer h-9 w-full border-b bg-transparent pt-2.5 font-PoppinsRegular text-sm font-normal outline outline-0 transition-all focus:outline-0 disabled:border-0 placeholder:opacity-0 focus:placeholder:opacity-100",

  CommonInputLabel:
    "after:content[''] pointer-events-none absolute left-0  -top-1.5 flex h-full w-full select-none !overflow-visible truncate text-[12px] font-normal leading-tight transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:transition-transform after:duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25]  peer-focus:text-[12px] peer-focus:leading-tight peer-focus:after:scale-x-100 peer-disabled:text-transparent",

  input:
    "border-primary  text-thirdColor placeholder-shown:border-thirdColor focus:border-thirdColor disabled:bg-thirdColor",

  errorInput:
    "border-red-500 text-red-500 placeholder-shown:border-red-500 focus:border-red-500 disabled:bg-red-500",

  inputLabel:
    "text-thirdColor after:border-thirdColor peer-focus:text-primary peer-focus:after:border-primary peer-disabled:peer-placeholder-shown:text-primary",

  errorInputLabel:
    "text-red-500 after:border-red-500 peer-focus:text-red-500 peer-focus:after:border-red-500 peer-disabled:peer-placeholder-shown:text-red-500",

  btn: "rounded-md sm:px-6 px-1 py-2 font-medium  bg-white text-[#FF4D4F] cursor-pointer",
  btnSecond:
    "rounded-md sm:px-6 px-1  cursor-pointer py-1 font-medium text-white bg-primary",
  form: "px-5 py-5 border-primary border-2 rounded-xl shadow-lg md:max-w-sm w-full  text-opacity-70",
  formH1: "text-thirdColor font-bold text-3xl mb-1",
  formP: "text-base font-normal text-thirdColor mb-7",
  formI: "text-secondary absolute  top-2 left-2 ",
  formBTN:
    "block w-full bg-primary mt-4 py-2 rounded-2xl text-secondary font-semibold mb-2 ",
  editInput:
    "border rounded-md w-full  mx-auto border-border  p-2 outline-none",
  hoverPopup:
    "mx-auto  h-full border-2 border-solid  p-5 w-full max-w-2xl hover:bg-opacity-70  shadow-lg hover:border-primary  hover:shadow-2xl rounded-xl",
};

export const layout = {
  section: `flex lg:flex-row flex-col gap-4 ${styles.paddingY}`,
  formInput: `py-10 w-full  px-5  ${styles.flexCenter}`,
};

export default styles;
