import { toast } from "react-hot-toast";

export const isUserLoggedOut = () => localStorage?.clear();

export const getToken = () => {
  const userString = localStorage.getItem("user");
  return userString ? JSON.parse(userString) : null;
};

// Common error handling function
export const handleErrors = (err, decryptData) => {
  try {
    const errorMessages = decryptData(err?.response?.data?.data);
    if (errorMessages?.status === false && err?.response?.status === 401) {
      // Call isUserLoggedOut function or perform logout logic here
      isUserLoggedOut();
      window.location.reload();
      toast.error(errorMessages?.message);
    } else {
      if (errorMessages?.message) {
        toast.error(errorMessages?.message);
      } else {
        toast.error("An error occurred, Please retry again later");
      }
    }
  } catch (error) {
    // Handle any additional errors during error handling
    console.error("Error handling the error:", error);
  }
};

export const formatDate = (dateString) => {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();

  return `${day}-${month}-${year} `;
};

export function shortname(file) {
  const filename = file;
  const shortname =
    filename?.length > 5
      ? filename?.substr(0, 5) + "..." + filename?.substr(-4)
      : filename;
  return <>{shortname}</>;
}

// Utility function to truncate a string and append ellipsis if needed
export const truncateString = (str, maxLength) => {
  return str.length > maxLength ? str.substring(0, maxLength) + "...." : str;
};

// Utility function to get Tailwind CSS class based on status
export const getStatusColorClass = (status) => {
  switch (status) {
    case "activate":
      return "px-2 py-1 text-[#52C41A] bg-[#F6FFED]"; // Green color for "Activate"
    case "deactivate":
      return "px-2 py-1 text-[#FAAD14] bg-[#FFF2E8]"; // Yellow color for "Deactivate"
    case "deleted":
      return "px-2 py-1 text-[#F5222D] bg-[#FFF1F0]"; // Red color for "Deleted"
    default:
      return "px-2 py-1 text-white bg-gray-500"; // Default color (you can set this to another color if needed)
  }
};

export const getStatusStyle = (status) => {
  switch (status) {
    case "inquiry":
      return "px-2 py-1 text-[#337ab7] bg-[#d9edf7]";
    case "pending":
      return "px-2 py-1 text-[#ec971f] bg-[#fcf8e3]";
    case "processing":
      return "px-2 py-1 text-[#31b0d5] bg-[#d9edf7]";
    case "canceled":
    case "failed":
      return "px-2 py-1 text-[#c9302c] bg-[#f2dede]";
    case "succeeded":
    case "confirm":
      return "px-2 py-1 text-[#5ec92c] bg-[#dff0d8]";
    default:
      return "px-2 py-1 text-[#000000] bg-[#ffffff]";
  }
};
