import React, { useState } from "react";
import { CgProfile } from "react-icons/cg";
import { Link } from "react-router-dom";
import MyAccount from "./MyAccount";
function Header() {
  const [showPopup, setShowPopup] = useState(false);

  const handleMouseEnter = () => {
    setShowPopup(true);
  };

  const handleMouseLeave = () => {
    setShowPopup(false);
  };
  return (
    <div className="bg-white p-5  border mb-3 relative">
      <Link
        to="/myAccount/changePassword"
        className="flex items-center gap-2 justify-end"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <CgProfile className="text-2xl" />
        <span>My Account</span>
      </Link>

      {showPopup && (
        <div
          className="absolute right-0 z-[60]"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <MyAccount />
        </div>
      )}
    </div>
  );
}

export default Header;
