import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { headerData } from "../Data/AllMapingData";
import { getToken } from "../Utils/util";
import { CiMenuFries } from "react-icons/ci";
import Logo from "../img/Logo.png";

function SideBar() {
  const location = useLocation();
  const { pathname } = location;
  const [OpenBar, setOpenBar] = useState(false);

  const userData = getToken();
  return (
    <div>
      <div className="bg-white sticky  z-50 h-screen overflow-y-scroll overflow-x-hidden border border-l">
        <div className={`h-screen lg:block hidden w-52 `}>
          <div className="pt-5 mb-11">
            <img src={Logo} alt="" srcSet="" className="w-3/5 mx-auto" />
          </div>
          <ul className="flex flex-col gap-px">
            {userData &&
              headerData
                ?.filter((data) => data?.roles?.includes(userData.role))
                .map((data, index) => (
                  <Link
                    to={data.pathname}
                    className={` ${
                      data.pathname === pathname
                        ? "bg-secondary text-primary border-r-2 border-primary"
                        : " text-thirdColor"
                    } h-12 flex items-center justify-start tracking-wide text-[13px]  hover:bg-secondary  transition duration-500`}
                    key={index}
                  >
                    <li
                      to={data.pathname}
                      className="flex items-center gap-3 pl-3"
                    >
                      <div className="text-lg">{data.img}</div>
                      <span>{data.pageName}</span>
                    </li>
                  </Link>
                ))}
          </ul>
        </div>
        {!OpenBar && (
          <div
            className="lg:hidden fixed bottom-5 left-5 "
            onClick={() => setOpenBar(!OpenBar)}
          >
            <CiMenuFries />
          </div>
        )}
      </div>
    </div>
  );
}

export default SideBar;
